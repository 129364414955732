import React from "react"
import { graphql } from "gatsby"

import Layout from '../components/layout'
import SEO from '../components/seo'

import "./blogTemplate.scss"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  if (!data) return "loading"
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
    <SEO title={frontmatter.title} />
      <section className="blog-post">
        <center>
          <h1>{frontmatter.title}</h1>
          <br/>
          <small>{frontmatter.date}</small>
          <br/>
          <br/>
        </center>
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title        
      }
    }
  }
`